import React , { useState , Fragment, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CardModal from './CardModal';

const TodayPicks = props => {
    // const data = props.data;
    
    const [data, setData] = useState([])

    const getList = async () => {
        const options = {method: 'GET', headers: {accept: '*/*', 'x-api-key': 'f68077f3-8fc1-51cf-a661-4d036a36b93e'}};

        fetch('https://api.reservoir.tools/sales/v4?includeTokenMetadata=true&limit=200', options)
        .then(response => response.json())
        .then(response => {
            console.log(response)
            setData(response.sales.filter(item => item.token?.image))
        })
        .catch(err => console.error(err));
    }

    useEffect(() => {
        if (data.length < 7) {
            getList()
        }
    }, [])

    const [visible , setVisible] = useState(12);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4);
    }
    const [modalShow, setModalShow] = useState(false);
    return (
        <Fragment>
        <section className="tf-section today-pick">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-live-auctions mg-bt-21">
                            <h2 className="tf-title pad-l-7">
                                Today's Picks
                            </h2>
                            <Link to="/explore-01" className="exp style2">EXPLORE MORE</Link>
                        </div>
                    </div>
                    {
                        data.slice(0,visible).map((item,index) => (
                            <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className={`sc-card-product ${item.feature ? 'comingsoon' : '' } `}>
                                    <div className="card-media">
                                        <Link to={`/item-details-01?contract=${item.token.contract}&tokenId=${item.token.tokenId}`}><img src={item.token.image} style={{minHeight: '290px'}} alt="niftylend" /></Link>
                                        {/* <Link to="/login" className="wishlist-button heart"><span className="number-like">{item.wishlist}</span></Link> */}
                                        {/* <div className="coming-soon">{item.feature}</div> */}
                                    </div>
                                    <div className="card-title">
                                        <h5 className="style2"><Link to={`/item-details-01?contract=${item.contract}&tokenId=${item.tokenId}`}>"{item.token.name}"</Link></h5>
                                        <div className="tags">{item.price.currency.symbol}</div>
                                    </div>
                                    <div className="meta-info">
                                        <div className="author">
                                            {/* <div className="avatar">
                                                <img src={item.imgAuthor} alt="niftylend" />
                                            </div> */}
                                            <div className="info">
                                                <span>Sale By</span>
                                                <h6> <Link to="#">{item.saleId.slice(0,6)}...{item.saleId.slice(-4)}</Link> </h6>
                                            </div>
                                        </div>
                                        <div className="price">
                                            <span>Current Price</span>
                                            <h5> {item.price.amount.native}</h5>
                                        </div>
                                    </div>
                                    {/* <div className="card-bottom">
                                        <button className="sc-button style bag fl-button pri-3 no-bg" onClick={() => setModalShow(true)}><span>Place Bid</span></button>
                                        <Link to="/activity-01" className="view-history reload">View History</Link>
                                    </div> */}
                                </div>
                            </div>
                        ))
                    }
                    {
                        visible < data.length && 
                        <div className="col-md-12 wrap-inner load-more text-center"> 
                            <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                        </div>
                    }
                </div>
            </div>
        </section>
        <CardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
         />
        </Fragment>
    );
}



TodayPicks.propTypes = {
    data: PropTypes.array.isRequired,
}


export default TodayPicks;
