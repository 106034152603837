import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Contact02 = () => {
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Authorization</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    {/* <li><Link to="#">Contact</Link></li> */}
                                    <li>Authorization</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="flat-form">
                                <h2 className="tf-title-heading ct style-2 mg-bt-12">
                                NFT Rental and Authorization <br /> Coming Soon
                                </h2>
                                <h5 className="sub-title ct style-1 pad-0-15">
                                We are building incredible services for individuals and businesses that are interested in enabling authorization and Authorization of their token while having their copyright kept in their hand. Content creators and businesses will be able profit from providing sustainable services for their clients.
                                </h5>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Contact02;
