import img1 from '../images/blog/thumb-1.jpg';
import img2 from '../images/blog/thumg-3-1.jpg';
import img3 from '../images/blog/thumb-4.jpg';
import img4 from '../images/blog/thumb-2.jpg';
import img5 from '../images/blog/thumb-5.jpg';
import img6 from '../images/blog/thumb-6-1.jpg';
import imga1 from '../images/avatar/avt-2.jpg';
import imga2 from '../images/avatar/avt-3.jpg';
import imga3 from '../images/avatar/avt-6.jpg';
import imga4 from '../images/avatar/avt-4.jpg';
import imga5 from '../images/avatar/avt-8.jpg';
import imga6 from '../images/avatar/avt-6.jpg';
const blogData = [
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumb-1.7f43abeb4e2f45331d59.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-2.e101dc63363945410d54.jpg',
        nameAuthor: 'SalvadorDali',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumg-3-1.375593ec01f87634624b.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-3.c03860ca1e2872296499.jpg',
        nameAuthor: 'Tyler Covington',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumb-4.0a24d0a7f8d68decab4d.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-6.0f735e283e35e7c8f00c.jpg',
        nameAuthor: 'Freddie Carpenter',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumb-2.342500c2ceb1bd8cb569.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-4.cbb2f9fb25c1e375d958.jpg',
        nameAuthor: 'SalvadorDali',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumb-5.d403c6bf02d8dbbd023e.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-8.5196d4a4839fce8a5b96.jpg',
        nameAuthor: 'Tyler Covington',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumb-6-1.4cd0cf1ab313225644ea.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-6.0f735e283e35e7c8f00c.jpg',
        nameAuthor: 'Freddie Carpenter',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumb-1.7f43abeb4e2f45331d59.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-2.e101dc63363945410d54.jpg',
        nameAuthor: 'SalvadorDali',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumg-3-1.375593ec01f87634624b.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-3.c03860ca1e2872296499.jpg',
        nameAuthor: 'Tyler Covington',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumb-4.0a24d0a7f8d68decab4d.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-6.0f735e283e35e7c8f00c.jpg',
        nameAuthor: 'Freddie Carpenter',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumb-2.342500c2ceb1bd8cb569.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-4.cbb2f9fb25c1e375d958.jpg',
        nameAuthor: 'SalvadorDali',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumb-5.d403c6bf02d8dbbd023e.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-8.5196d4a4839fce8a5b96.jpg',
        nameAuthor: 'Tyler Covington',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
    {
        img: 'https://axiesreact.surielementor.com/static/media/thumb-6-1.4cd0cf1ab313225644ea.jpg',
        title: 'The Next Big Trend In Crypto',
        imgAuthor: 'https://axiesreact.surielementor.com/static/media/avt-6.0f735e283e35e7c8f00c.jpg',
        nameAuthor: 'Freddie Carpenter',
        time: 'Feb 19, 2021',
        content: 'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...'
    },
]

export default blogData;