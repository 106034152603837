import img1 from '../images/slider/slide_1.png';
import img2 from '../images/slider/slide_1.png';
import imgbg1 from '../images/slider/bg_slide_1.png'
import imgbg2 from '../images/slider/bg_slide_1.png'


const heroSliderData = [
    {
        title_1: "Discover, find,",
        title_2: "Extraordinary NFTs",
        title_3: "Trading mechanism",
        description: "Discover, find Extraordinary NFTs trading mechanism",
        // img: img1,
        imgbg: imgbg1,
        img: 'https://axiesreact.surielementor.com/static/media/slide_1.b0f158a748fcae52d46f.png',
        class:'left'
    },
    {
        title_1: "Discover, find,",
        title_2: "Extraordinary NFTs",
        title_3: "Trading mechanism",
        description: "Discover, find Extraordinary NFTs trading mechanism",
        // img: img2,
        img: 'https://axiesreact.surielementor.com/static/media/slide_1.b0f158a748fcae52d46f.png',
        imgbg: imgbg2,
        class:'center'
    },
    {
        title_1: "Discover, find,",
        title_2: "Extraordinary NFTs",
        title_3: "Trading mechanism",
        description: "Discover, find Extraordinary NFTs trading mechanism",
        // img: img2,
        img: 'https://axiesreact.surielementor.com/static/media/slide_1.b0f158a748fcae52d46f.png',
        imgbg: imgbg2,
        class:'right'
    },
    
]

export default heroSliderData;