import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import heroSliderData from '../assets/fake-data/data-slider';
import Slider from '../components/slider/Slider';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import LiveAuction from '../components/layouts/LiveAuction';
import TopSeller from '../components/layouts/TopSeller';
import topSellerData from '../assets/fake-data/data-top-seller'
import TodayPicks from '../components/layouts/TodayPicks';
import todayPickData from '../assets/fake-data/data-today-pick';
import PopularCollection from '../components/layouts/PopularCollection';
import popularCollectionData from '../assets/fake-data/data-popular-collection';
import Create from '../components/layouts/Create';
// import alchemy from '../utils/alchemy'
// alchemy.core
//     .getTokenBalances('0x994b342dd87fc825f66e51ffa3ef71ad818b6893')
//     .then(console.log);


const Home01 = () => {

    return (
        <div className='home-1'>
            <Header />
            <Slider data={heroSliderData} />
            <PopularCollection data={popularCollectionData} />
            <TodayPicks data={todayPickData} />
            <LiveAuction data={liveAuctionData} />
            {/* <TopSeller data={topSellerData} /> */}
            <Create />
            <Footer />
        </div>
    );
}

export default Home01;
